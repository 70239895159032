<template>
  <div class="container">
    <div class="wrap">
      <el-form ref="set-password-form" class="wrap-form" :model="passwordForm" :rules="passwordFormRules">
        <el-form-item class="wrap-form-item" prop="tel">
          <el-row type="flex" justify="start">
            <el-input type="text" placeholder="请输入手机号" style="width: 300px" v-model="passwordForm.tel" />
            <div class="button-gary send-button" @click="getCaptcha" v-if="!timer">{{ sendCaptchaText }}</div>
            <div class="button send-button disabled" v-else>{{ count }}秒后重新获取</div>
          </el-row>
        </el-form-item>
        <el-form-item prop="captcha">
          <el-input type="text" placeholder="请输入验证码" v-model="passwordForm.captcha" maxlength="6" />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="请输入6-16位英文及数字组合密码"
            v-model="passwordForm.password"
            maxlength="16"
          />
        </el-form-item>
        <el-form-item prop="repassword">
          <el-input
            type="password"
            placeholder="请再次输入上面的密码"
            v-model="passwordForm.repassword"
            maxlength="16"
          />
        </el-form-item>
        <el-form-item>
          <div class="button-radius ok-button font-s20" @click="submit">确定</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { service } from '@/utils/axios'
import store from '@/store'
import { mapGetters } from 'vuex'
export default {
  data() {
    // 验证
    const validateTel = (_, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号'))
      }
      if (!/^1[3-9]\d{9}$/.test(value)) {
        return callback(new Error('手机号格式错误'))
      }
      callback()
    }
    const validateCaptcha = (_, value, callback) => {
      if (!value) {
        return callback(new Error('请输入验证码'))
      }
      if (!/^\d{6}$/.test(value)) {
        return callback(new Error('验证码格式错误'))
      }
      callback()
    }
    const validatePassword = (_, value, callback) => {
      if (!value) {
        return callback(new Error('请输入密码'))
      }
      if (!/^(?![a-zA-z]+$)(?!\d+$)[a-zA-Z\d_]{6,16}$/.test(value)) {
        return callback(new Error('密码必须是6-16位英文和数字组合'))
      }
      if (this.passwordForm.repassword !== '') {
        this.$refs['set-password-form'].validateField('repassword')
      }
      callback()
    }
    const validateRepassword = (_, value, callback) => {
      if (!value) {
        return callback(new Error('请再次输入密码'))
      }
      if (value != this.passwordForm.password) {
        return callback(new Error('两次输入的密码不一致'))
      }
      callback()
    }

    return {
      passwordForm: {
        tel: '',
        captcha: '',
        password: '',
        repassword: '',
      },
      passwordFormRules: {
        tel: { require: true, validator: validateTel, trigger: 'blur' },
        captcha: { require: true, validator: validateCaptcha, trigger: 'blur' },
        password: { require: true, validator: validatePassword, trigger: 'blur' },
        repassword: { require: true, validator: validateRepassword, trigger: 'blur' },
      },
      // 计时器
      timer: null,
      // 剩余时间
      count: 0,
      // 默认时间
      defaultCount: 60,
      sendCaptchaText: '发送验证码',
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    async getCaptcha() {
      this.$refs['set-password-form'].validateField('tel', async e => {
        if (e || this.timer) {
          return
        }
        const res = await service.post('/app/send_verify_code', { tel: this.passwordForm.tel })
        if (res.data.ret_code === 0) {
          this.count = this.defaultCount
          this.timer = window.setInterval(() => {
            if (this.count > 0 && this.count <= this.defaultCount) {
              this.count--
            } else {
              this.sendCaptchaText = '重新发送'
              this.timer = null
              window.clearInterval(this.timer)
            }
          }, 1000)
        }
      })
    },
    async submit() {
      this.$refs['set-password-form'].validate(async v => {
        if (v) {
          const res = await service.post('/app/reset_password', {
            new_pwd: this.passwordForm.password,
            tel: this.passwordForm.tel,
            verify: this.passwordForm.captcha,
          })
          if (res.data.ret_code === 0) {
            if (this.user.tel) {
              this.$message.success('重置密码成功，即将跳转回原页面')
              setTimeout(async () => {
                const next = await store.dispatch('user/loginByPwd', {
                  tel: this.passwordForm.tel,
                  password: this.passwordForm.password,
                })
                this.$router.replace(next)
              }, 1000)
            } else {
              this.$message.success('重置密码成功，即将跳转登录页')
              setTimeout(() => {
                this.$router.replace('/login')
              }, 1000)
            }
          }
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  padding-top: 30px;
  padding-bottom: 30px;
  .wrap {
    max-width: 1200px;
    height: 600px;
    background-color: #ffffff;
    background-clip: content-box;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    &-form {
      width: 500px;
      margin: 0 auto;
      padding-top: 60px;
    }
    /deep/ .el-input {
      width: 455px;
    }
    .send-button {
      width: 145px;
      margin-left: 10px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }
    .disabled {
      cursor: default;
      background: #fff;
      border: 1px solid #999;
      box-sizing: border-box;
      color: #999;
      text-align: center;
    }
    .ok-button {
      width: 455px;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
